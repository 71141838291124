import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import Aside from './Aside';
import { BASE_URL } from '../apiConfig';
import useAuth from '../../Hooks/useAuth';

function WalletHistory() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [creditLimit, setCreditLimit] = useState(0);
    const { isAuthenticated } = useAuth();


    const [currentPage, setCurrentPage] = useState(1);
    const [transactionsPerPage, setTransactionsPerPage] = useState(10);
    const [customPerPage, setCustomPerPage] = useState('');
    const [isCustom, setIsCustom] = useState(false);

    useEffect(() => {
        axios.get(`${BASE_URL}/customer-wallet-history`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
        })
            .then(response => {
                setTransactions(response.data.data.transactions);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching wallet transactions:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            axios.get(`${BASE_URL}/credit-limit`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                },
            })
                .then(response => {
                    setCreditLimit(response.data.data.credit_limit);
                })
                .catch(error => {
                    console.error("Error fetching credit limit:", error);
                })
                .finally(() => setLoading(false));
        }
    }, [isAuthenticated]);

    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions = transactionsPerPage === 'All' ? transactions : transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

    const totalPages = transactionsPerPage === 'All' ? 1 : Math.ceil(transactions.length / transactionsPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handlePerPageChange = (event) => {
        const value = event.target.value;
        if (value === 'Custom') {
            setIsCustom(true);
        } else {
            setIsCustom(false);
            setTransactionsPerPage(value === 'All' ? 'All' : parseInt(value, 10));
            setCurrentPage(1);
        }
    };

    const handleCustomInputChange = (event) => {
        setCustomPerPage(event.target.value);
    };

    const applyCustomPerPage = () => {
        const customValue = parseInt(customPerPage, 10);
        if (!isNaN(customValue) && customValue > 0) {
            setTransactionsPerPage(customValue);
            setCurrentPage(1);
            setIsCustom(false);
            setCustomPerPage('');
        } else {
            alert("Please enter a valid number.");
        }
    };

    return (
        <>
            <Header />
            <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-between align-items-center d-md-none py-4">
                                    <h3 className="fs-5 mb-0">Wallet Transactions</h3>
                                    <button className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasAccount" aria-controls="offcanvasAccount">
                                        <i className="bi bi-text-indent-left fs-3" />
                                    </button>
                                </div>
                            </div>
                            <Aside />
                            <div className="col-lg-9 col-md-8 col-12">
                                <div className="py-6 p-md-6 p-lg-10">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h2>Wallet Transaction History</h2>
                                        <div className="d-flex align-items-center">
                                            <select className="form-select w-auto me-2" value={isCustom ? 'Custom' : transactionsPerPage} onChange={handlePerPageChange}>
                                                <option value="5">5 per page</option>
                                                <option value="10">10 per page</option>
                                                <option value="15">15 per page</option>
                                                <option value="20">20 per page</option>
                                                <option value="All">Show All</option>
                                                <option value="Custom">Custom</option>
                                            </select>
                                            {isCustom && (
                                                <div className="d-flex">
                                                    <input
                                                        type="number"
                                                        className="form-control w-auto"
                                                        placeholder="Enter number"
                                                        value={customPerPage}
                                                        onChange={handleCustomInputChange}
                                                    />
                                                    <button className="btn btn-primary ms-2" onClick={applyCustomPerPage}>Apply</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <p className="mb-6 text-primary">Current Limit ( ₹{creditLimit ?? 0} )</p>
                                    <div className="table-responsive-xxl border-0">
                                        {loading ? (
                                            <p>Loading transactions...</p>
                                        ) : (
                                            <>
                                                <table className="table mb-0 text-nowrap table-centered">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Order Number</th>
                                                            <th>Description</th>
                                                            <th>Credit Amount</th>
                                                            <th>Debit Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentTransactions.length > 0 ? (
                                                            currentTransactions.map(transaction => (
                                                                <tr key={transaction.id}>
                                                                    <td>{new Date(transaction.datetime).toLocaleDateString()}</td>
                                                                    <td>#{transaction.order_number}</td>
                                                                    <td>{transaction.description}</td>
                                                                    <td className="text-success">{transaction.type === 'c' ? `₹${transaction.amount.toFixed(2)}` : '-'}</td>
                                                                    <td className="text-danger">{transaction.type === 'd' ? `₹${transaction.amount.toFixed(2)}` : '-'}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">No transactions found.</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>

                                                {transactions.length > transactionsPerPage && transactionsPerPage !== 'All' && (
                                                    <div className="d-flex justify-content-between mt-4">
                                                        <button className="btn btn-outline-primary" onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                                                        <span>Page {currentPage} of {totalPages}</span>
                                                        <button className="btn btn-outline-primary" onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default WalletHistory;
