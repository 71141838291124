import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, BASE_IMAGE_URL } from '../apiConfig';

export default function Partner() {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/partner-list`);
        console.log(response);
        
        if (response.data.status) {
          setPartners(response.data.data.partners);
        } else {
          throw new Error('Failed to fetch partners');
        }
      } catch (error) {
        console.error('Error fetching partners:', error.message);
      }
    };

    fetchPartners();
  }, []);

  return (
    <section className="partner-section">
      <h3 className="partner-title">Explore Our Trusted Partnerships</h3>
      <div className="partner-slider">
        <div className="partner-track">
          {[...partners, ...partners].map((partner, index) => (
            <div key={index} className="partner-item">
              <img
                src={`${BASE_IMAGE_URL}/${partner.image}`}
                alt="Partner Logo"
                className="partner-logo"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
