import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { fetchProducts, addToCart } from '../../Api/productApi';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { BASE_IMAGE_URL } from '../apiConfig';
import Aside from './Aside';
import { usePriceRange } from '../../Contexts/PriceRangeContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from '../../Hooks/useAuth';
import Loader from '../Loader';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function Product() {
    const query = useQuery();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { priceRange, setPriceRange } = usePriceRange();
    const [hasSelectedProducts, setHasSelectedProducts] = useState(false);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10000);
    const categorySlug = query.get('category') || '';
    const subcategorySlug = query.get('subcategory') || '';
    const brands = query.get('brands') || '';
    const searchQuery = query.get('search') || '';
    const { isAuthenticated, token } = useAuth();
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [brandlists, setBrands] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                setLoading(true);
                const response = await fetchProducts(currentPage, categorySlug, subcategorySlug, brands, token, searchQuery, itemsPerPage);
                console.log(response);

                if (response.status) {
                    setProducts(response.data.data);
                    setTotalPages(response.data.last_page);
                    setMinPrice(response.min_price);
                    setMaxPrice(response.max_price);
                    setPriceRange([response.min_price, response.max_price]);
                    const validProducts = response.data.data.filter(product => product.brands?.brand_name);
                    const uniqueBrands = Array.from(new Set(validProducts.map(product => product.brands.brand_name)));
                    setBrands(uniqueBrands);
                } else {
                    console.error('Failed to fetch products');
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductData();
    }, [currentPage, categorySlug, subcategorySlug, brands, searchQuery, itemsPerPage]);

    useEffect(() => {
        const filterProductsByPrice = () => {
            const filtered = products.filter(product => {
                const price = product.discount_price !== null && product.discount_price !== undefined
                    ? product.discount_price
                    : product.base_price;
                if (price === null || price === undefined) {
                    return true;
                }
                return price >= priceRange[0] && price <= priceRange[1];
            });

            setFilteredProducts(filtered);
        };

        filterProductsByPrice();
    }, [products, priceRange]);
    useEffect(() => {
        const comparisonList = JSON.parse(localStorage.getItem('comparisonList')) || [];
        setHasSelectedProducts(comparisonList.length > 0);
    }, [products]);

    const handleProductSelect = (product) => {
        const currentSelection = JSON.parse(localStorage.getItem('comparisonList')) || [];
        const productIndex = currentSelection.findIndex(p => p.id === product.id);
        if (productIndex > -1) {
            currentSelection.splice(productIndex, 1);
        } else {
            currentSelection.push(product);
        }
        localStorage.setItem('comparisonList', JSON.stringify(currentSelection));
        setProducts([...products]);
    };

    const isProductSelected = (productId) => {
        const comparisonList = JSON.parse(localStorage.getItem('comparisonList')) || [];
        return comparisonList.some(p => p.id === productId);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSortChange = (event) => {
        const sortOption = event.target.value;
        let sortedProducts = [...filteredProducts];
        if (sortOption === 'priceLowToHigh') {
            sortedProducts.sort((a, b) => a.discount_price - b.discount_price);
        } else if (sortOption === 'priceHighToLow') {
            sortedProducts.sort((a, b) => b.discount_price - a.discount_price);
        }
        setFilteredProducts(sortedProducts);
    };

    const handleAddToCart = async (product) => {
        if (!isAuthenticated) {
            toast.error('You must be logged in to add to cart.');
            setTimeout(() => {
                navigate('/signin');
            }, 1000);
            return;
        }
        const quantity = 1;
        const cartItem = {
            product_id: product.id,
            quantity
        };
        try {
            await addToCart(cartItem, token);
            setIsAddedToCart(true);
            toast.success('Product added to cart successfully');
            navigate('/cart');
        } catch (error) {
            toast.error('Failed to add product to cart.');
        }
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    return (
        <>
            <Header />
            <main>
                <div className="mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/shop">Shop</Link></li>
                                        {categorySlug && (
                                            <li className="breadcrumb-item">
                                                <Link to={`/shop?category=${categorySlug}`}>{categorySlug}</Link>
                                            </li>
                                        )}
                                        {subcategorySlug && (
                                            <li className="breadcrumb-item active" aria-current="page">
                                                {subcategorySlug}
                                            </li>
                                        )}
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 mb-lg-14 mb-8">
                    <div className="container">
                        <div className="row gx-10">
                            <Aside minPrice={minPrice} maxPrice={maxPrice} brands={brandlists} />
                            <section className="col-lg-9 col-md-12">
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4 gap-3">

                                    <div className="text-center text-md-start w-100">
                                        <p className="mb-0 fw-semibold">{filteredProducts.length} Products found</p>
                                    </div>

                                    <div className="d-flex flex-wrap row-cols-1 row-cols-md-auto align-items-center justify-content-between w-100 gap-2">

                                        <div className="d-lg-none w-100">
                                            <button className="btn btn-outline-secondary w-100 d-flex align-items-center justify-content-center"
                                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasCategory" aria-controls="offcanvasCategory">
                                                <i className="feather-icon icon-filter me-1"></i> Filters
                                            </button>
                                        </div>

                                        <div className="flex-grow-1">
                                            <select className="form-select w-100 shadow-sm border rounded" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                <option value={9}>Show: 9</option>
                                                <option value={20}>Show: 20</option>
                                                <option value={30}>Show: 30</option>
                                                <option value={50}>Show: 50</option>
                                            </select>
                                        </div>

                                        <div className="flex-grow-1">
                                            <select className="form-select w-100 shadow-sm border rounded" onChange={handleSortChange}>
                                                <option value="">Sort By</option>
                                                <option value="priceLowToHigh">Price: Low to High</option>
                                                <option value="priceHighToLow">Price: High to Low</option>
                                            </select>
                                        </div>

                                        {hasSelectedProducts && (
                                            <div className="w-auto text-center mt-2">
                                                <Link to="/comparison" className="btn btn-primary shadow">Compare Selected</Link>
                                            </div>
                                        )}

                                    </div>

                                </div>



                                <div className={`row g-4 ${searchQuery ? "row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-1" : "row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1"} mt-2`}>
                                    {filteredProducts.map(product => (
                                        <div key={product.id} className="col">
                                            <div className="card card-product">
                                                <div className="card-body">
                                                    <div className="text-center position-relative">
                                                        <div className="position-absolute top-0 start-0">
                                                            {product.average_rating >= 3 ? (
                                                                <span className="badge bg-warning text-dark"> <i className="bi bi-star-fill text-dark me-1" /> {product.average_rating}</span>
                                                            ) : (
                                                                <p></p>
                                                            )}
                                                        </div>
                                                        <Link to={`/product/${product.product_slug}`}>
                                                            <img src={`${BASE_IMAGE_URL}/${product.primary_image}`}
                                                                alt={product.product_name} className="mb-3 img-fluid" />
                                                        </Link>
                                                    </div>
                                                    <h2 className="fs-6">
                                                        <Link to={`/product/${product.product_slug}`} className="text-inherit text-decoration-none">
                                                            {product.product_name}
                                                        </Link>
                                                    </h2>

                                                    {product.brands ? (
                                                        <span> <b className='fs-7'>Brand  :</b> {product.brands.brand_name}</span>
                                                    ) : (
                                                        <span></span>
                                                    )}

                                                    <ul>
                                                        {product.specification.slice(0, 3).map((spec, index) => (
                                                            <>
                                                                {spec.description ? (
                                                                    <li key={index}> {spec.description}</li>
                                                                ) : (
                                                                    <span></span>
                                                                )}
                                                            </>
                                                        ))}
                                                    </ul>
                                                    <div className="d-flex justify-content-between align-items-center mt-3">
                                                        <div>
                                                            {isAuthenticated ? (
                                                                <>
                                                                    {product.custom_rates && product.custom_rates.length > 0 ? (
                                                                        <div className="mt-1">
                                                                            <p className='text-small' style={{ fontSize: '0.7rem' }}>(This Price Only for you By Price List)</p>
                                                                            <span className='text-dark'>Price: ₹{product.custom_rates[0].custom_rate}</span>
                                                                            <span className="text-decoration-line-through text-muted ms-2">
                                                                                ₹{product.discount_price}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="mt-1">
                                                                            <span className="fw-bold text-dark">
                                                                                ₹{product.discount_price || product.base_price}
                                                                            </span>
                                                                            {product.discount_price && product.discount_price < product.base_price && (
                                                                                <>
                                                                                    <span className="text-decoration-line-through text-muted ms-2">
                                                                                        ₹{product.base_price}
                                                                                    </span>

                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <div className="text-muted mt-1">

                                                                </div>
                                                            )}
                                                            <div className="d-flex align-items-center mt-2">
                                                                <label className='me-2'>Compare</label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isProductSelected(product.id)}
                                                                    onChange={() => handleProductSelect(product)}
                                                                    id={`checkbox-${product.id}`}
                                                                    className="form-check-input"
                                                                />

                                                            </div>
                                                            <p style={{ color: product.stock_quantity > 0 ? 'green' : 'red' }}>
                                                                {product.stock_quantity > 0 ? `In stock (${product.stock_quantity})` : 'Out of stock'}
                                                            </p>
                                                        </div>
                                                        <div>

                                                            <>
                                                                {product.is_in_cart ? (
                                                                    <a className="btn btn-outline-primary btn-sm" onClick={() => navigate('/cart')}>
                                                                        <i className="bi bi-cart me-2"></i> View
                                                                    </a>
                                                                ) : (
                                                                    <a
                                                                        className={`btn btn-sm ${product.stock_quantity === 0 ? 'btn-secondary' : 'btn-primary'}`}
                                                                        onClick={product.stock_quantity > 0 ? () => handleAddToCart(product) : null}
                                                                        disabled={product.stock_quantity === 0}
                                                                    >
                                                                        <i className="bi bi-cart me-2"></i> Add
                                                                    </a>

                                                                )}
                                                            </>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="row mt-8 pt-6">
                                    <div className="col">
                                        {totalPages > 1 && (
                                            <nav>
                                                <ul className="pagination justify-content-center">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <button
                                                                className="page-link"
                                                                onClick={() => handlePageChange(currentPage - 1)}
                                                            >
                                                                &laquo; Previous
                                                            </button>
                                                        </li>
                                                    )}
                                                    {[...Array(totalPages)].map((_, index) => (
                                                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                            <button
                                                                className="page-link"
                                                                onClick={() => handlePageChange(index + 1)}
                                                            >
                                                                {index + 1}
                                                            </button>
                                                        </li>
                                                    ))}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} >
                                                                Next &raquo;  </button>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            <ToastContainer />
        </>
    );
}