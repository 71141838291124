import React, { useState } from 'react';
import Header from '../Pages/Header';
import Footer from '../Pages/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../apiConfig';

function ContactUs() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        description: '',
        email: '',
        phone: '',
        employee_designation: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let newErrors = {};
        if (!formData.first_name) newErrors.first_name = "First name is required.";
        if (!formData.last_name) newErrors.last_name = "Last name is required.";
        if (!formData.employee_designation) newErrors.employee_designation = "Employee Designation is required.";
        if (!formData.company_name) newErrors.company_name = "Company name is required.";
        if (!formData.email) newErrors.email = "Email is required.";
        if (!formData.phone) newErrors.phone = "Phone number is required.";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            toast.error("Please fix the errors in the form.");
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/contact-us`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();

            if (response.ok) {
                toast.success("Inquiry submitted successfully!");
                setFormData({
                    first_name: '', last_name: '', company_name: '', description: '', email: '', phone: '', employee_designation: ''
                });
                setErrors({});
            } else {
                toast.error(result.errors || "Something went wrong!");
            }
        } catch (error) {
            toast.error("Error submitting inquiry. Please try again.");
        }
    };

    return (
        <>
            <Header />
            <main>
                <section className="my-lg-14 my-8">
                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-2 col-lg-8 col-12">
                                <div className="mb-8">
                                    <h1 className="h3"> V2U Mart Inquiries</h1>
                                    <p className="lead mb-0">For any inquiries regarding V2U Mart, please use this form.</p>
                                </div>
                                <form className="row needs-validation" onSubmit={handleSubmit} noValidate>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="first_name">First Name<span className="text-danger">*</span></label>
                                        <input type="text" id="first_name" name="first_name" className="form-control" value={formData.first_name} onChange={handleChange} required />
                                        {errors.first_name && <div className="text-danger">{errors.first_name}</div>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="last_name">Last Name<span className="text-danger">*</span></label>
                                        <input type="text" id="last_name" name="last_name" className="form-control" value={formData.last_name} onChange={handleChange} required />
                                        {errors.last_name && <div className="text-danger">{errors.last_name}</div>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="company_name">Company Name<span className="text-danger">*</span></label>
                                        <input type="text" id="company_name" name="company_name" className="form-control" value={formData.company_name} onChange={handleChange} required />
                                        {errors.company_name && <div className="text-danger">{errors.company_name}</div>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="employee_designation">Employee Designation<span className="text-danger">*</span></label>
                                        <input type="text" id="employee_designation" name="employee_designation" className="form-control" value={formData.employee_designation} onChange={handleChange} required />
                                        {errors.employee_designation && <div className="text-danger">{errors.employee_designation}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label" htmlFor="description">Message</label>
                                        <textarea className="form-control" id="description" name="description" value={formData.description} onChange={handleChange} required />
                                        {errors.description && <div className="text-danger">{errors.description}</div>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="email">Email<span className="text-danger">*</span></label>
                                        <input type="email" id="email" name="email" className="form-control" value={formData.email} onChange={handleChange} required />
                                        {errors.email && <div className="text-danger">{errors.email}</div>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="phone">Phone Number<span className="text-danger">*</span></label>
                                        <input type="number" id="phone" name="phone" className="form-control" value={formData.phone} onChange={handleChange} required />
                                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                    </div>

                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <ToastContainer />
        </>
    );
}

export default ContactUs;
