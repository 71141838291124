import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../apiConfig'; 
import Header from './Header';
import Footer from './Footer';

function PrivacyPolicy() {
    const [policy, setPolicy] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPrivacyPolicy = async () => {
            try {
                const response = await fetch(`${BASE_URL}/privacyPolicies`);
                const data = await response.json();
                if (response.ok) {
                    setPolicy(data); 
                } else {
                    setError(data.message);
                }
            } catch (err) {
                setError('Failed to load policy');
            } finally {
                setLoading(false);
            }
        };

        fetchPrivacyPolicy();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
        <Header></Header>
        <div className="container  px-5 py-5">
            <h1 className='text-center'>{ policy.title }</h1>
            <div dangerouslySetInnerHTML={{ __html: policy?.description }} />
        </div>
        <Footer></Footer>
        </>
    );
}

export default PrivacyPolicy;
