import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';

function FaqsPage() {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');


    useEffect(() => {
   
        const fetchFaqs = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/faqs`);
                setFaqs(response.data.faqs);
                setLoading(false);
            } catch (err) {
                setError('Error fetching data');
                setLoading(false);
            }
        };

        fetchFaqs();
    }, []); 

    if (loading) {
        return <div>Loading...</div>; 
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <Header />
            <main>
                <section className="my-lg-14 my-8">
                    <div className="container mt-5">
                        <div className="col-xl-12 col-lg-8 col-md-12">
                        <h1 className="mb-1 text-center">V2U Mart FAQS</h1>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {faqs.length === 0 ? (
                                    <p>No FAQs available.</p> 
                                ) : (
                                    faqs.map((faq, index) => (
                                        <div className="accordion-item py-4" key={faq.id}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a
                                                    href="#"
                                                    className="fs-5 text-inherit h4 collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapse${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`flush-collapse${index}`}
                                                >
                                                    <i className="feather-icon icon-map-pin me-2 text-muted" />
                                                    {faq.question}
                                                    <i className="feather-icon icon-chevron-down ms-2 collapse-icon collapsed" data-bs-target={`#flush-collapse${index}`} />
                                                </a>
                                            </div>
                                            <div id={`flush-collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                <div className="mt-5">
                                                    <div className="row">
                                                        <p>{faq.answer}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default FaqsPage;
