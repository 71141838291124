// const BASE_URL = 'https://admin.v2umart.krishnasoftweb.com/api';
// const BASE_IMAGE_URL = 'https://admin.v2umart.krishnasoftweb.com';
// const Website_url = "https://v2umart.krishnasoftweb.com";

// const BASE_URL = 'http://192.168.1.9:8800/api';   
// const BASE_IMAGE_URL = 'http://192.168.1.9:8800';
// const Website_url = "http://192.168.1.9:3000";

const BASE_URL = 'https://admin.v2umart.com/api';
const BASE_IMAGE_URL = 'https://admin.v2umart.com/';
const Website_url = "https://v2umart.com";

export { BASE_URL, BASE_IMAGE_URL ,Website_url};