import axios from 'axios';
import { BASE_URL } from '../Componets/apiConfig';


export const fetchProducts = async (page = 1, category = '', subcategory = '', brands = [], token = '', search = '', itemsPerPage = '') => {
    try {
        console.log(itemsPerPage);

        const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
        const url = `${BASE_URL}/product-list?page=${page}&category=${category}&subcategory=${subcategory}&brands=${brands}&search=${encodeURIComponent(search)}&itemsPerPage=${itemsPerPage}`;
        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching products:', error);
        return { data: { data: [], last_page: 1 }, status: false };
    }
};
export const fetchCategories = async () => {
    try {
        const response = await fetch(`${BASE_URL}/category-list`);
        const data = await response.json();
        if (data.status) {
            return data.data.category;
        } else {
            throw new Error(data.message || 'Failed to fetch categories');
        }
    } catch (error) {
        console.error('Error fetching categories:', error);
        return [];
    }
};

export const fetchSubCategories = async (categorySlug) => {
    try {
        const response = await axios.get(`${BASE_URL}/category/${categorySlug}`);
        if (response.data && response.data.status === true) {
            return response.data.data.category.sub_categories;
        } else {
            console.error('Failed to fetch subcategories:', response.data.message);
            return [];
        }
    } catch (error) {
        console.error('Error fetching subcategories:', error);
        return [];
    }
};

export const fetchsinglesubCategories = async (categorySlug) => {
    try {
        const response = await axios.get(`${BASE_URL}/category/${categorySlug}`);
        if (response.data && response.data.status === true) {
            return response.data.data.category.sub_categories;
        } else {
            console.error('Failed to fetch categories:', response.data.message);
            return [];
        }
    } catch (error) {
        console.error('Error fetching categories:', error);
        return [];
    }
};

export const fetchsingleproduct = async (productSlug, token) => {
    try {
        const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
        const url = `${BASE_URL}/productdetail/${productSlug}`;
        const response = await axios.get(url, config);

        if (response.data && response.data.status === true) {
            return response.data.data;
        } else {
            console.error('Failed to fetch product:', response.data.message);
            return null;
        }
    } catch (error) {
        console.error('Error fetching product:', error);
        return null;
    }
};

export const fetchRelatedProducts = async (productSlug, token) => {
    try {
        const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
        const url = `${BASE_URL}/related-product/${productSlug}`;
        const response = await axios.get(url, config);
        if (response.data && response.data.status === true) {
            return response.data.data;
        } else {
            console.error('Failed to fetch related products:', response.data.message);
            return [];
        }
    } catch (error) {
        console.error('Error fetching related products:', error);
        throw error;
    }
};

// New API Methods


export const submitReview = async (reviewData, token) => {
    try {
        const response = await axios.post(`${BASE_URL}/review-store`, reviewData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error submitting review:', error);
        throw error;
    }
};

export const addToCart = async (cartItem, token) => {
    try {
        const response = await axios.post(`${BASE_URL}/add-to-cart`, cartItem, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error adding product to cart:', error);
        throw error;
    }
};
export const fetchCartList = async (token) => {
    try {
        const response = await fetch(`${BASE_URL}/cart-list`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        } else {
            throw new Error('Response is not JSON');
        }
    } catch (error) {
        console.error('Error fetching cart list:', error);
        throw error;
    }
};
export const updateCartItem = async (id, quantity, token) => {
    try {
        const formData = new FormData();
        formData.append('cart_id', id);
        formData.append('quantity', quantity);

        const response = await axios.post(`${BASE_URL}/update-cart`, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating cart item:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const removeCartItem = async (id, token) => {
    try {
        const formData = new FormData();
        formData.append('cart_id', id);
        const response = await fetch(`${BASE_URL}/delete-cart`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        } else {
            throw new Error('Response is not JSON');
        }
    } catch (error) {
        console.error('Error removing cart item:', error);
        throw error;
    }
};