import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../apiConfig';
import axios from 'axios';

export default function Footer() {
    const [socialLinks, setSocialLinks] = useState([]);
    
    useEffect(() => {
        const fetchSocialLinks = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/social-links`);
                if (response.data.status) {
                    setSocialLinks(response.data.SocialLink);
                    console.log(response.data.SocialLink);
                    
                }
            } catch (error) {
                console.error("Error fetching social links:", error);
            }
        };

        fetchSocialLinks();
    }, []);

    const getSocialIcon = (platform) => {
        switch (platform.toLowerCase()) {
            case "facebook":
                return (
                    <i class="bi bi-facebook"></i>
                );
            case "instagram":
                return (
                    <i class="bi bi-instagram"></i>
                );
            case "linkedin":
                return <i className="bi bi-linkedin"></i>;
            case "youtube":
                return <i className="bi bi-youtube"></i>;
            case "twitter":
                return <i class="bi bi-twitter-x"></i>;
            default:
                return null;
        }
    };
    return (
        <>
            <footer className="footer mt-5 pt-5 p-0">
                <div className="container">
                    <div className="row g-4 py-4">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="row g-4">
                                <div className="col-6 col-sm-6 col-md-3">
                                    <h6 className="mb-4">Shop for Products</h6>
                                    {/* list */}
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Laptop</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Desktop</a></li>
                                        <li className="nav-item mb-2"><a href="#1" className="nav-link">Printer</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Ink &amp; Toner</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Monitors</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Accessories</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Care Pack</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Support</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Latest Offers</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Latest Products</a></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-6 col-md-3">
                                    <h6 className="mb-4">Information</h6>
                                    <ul className="nav flex-column">
                                        {/* list */}
                                        <li className="nav-item mb-2"><a href="/privacy-policy" className="nav-link">Privacy Policy</a></li>
                                        <li className="nav-item mb-2"><a href="/terms-conditions" className="nav-link">Terms &amp; Conditions</a></li>
                                        <li className="nav-item mb-2"><a href="/cancellation-refund-policy" className="nav-link">Cancellation and Refund Policy</a></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-6 col-md-3">
                                    <h6 className="mb-4">Customer Service</h6>
                                    <ul className="nav flex-column">
                                        {/* list */}
                                        <li className="nav-item mb-2"><a href="/about-us" className="nav-link">
                                            About Us</a>
                                        </li>
                                        <li className="nav-item mb-2"><a href="/faq" className="nav-link">FAQs</a></li>
                                        <li className="nav-item mb-2"><a href="/contact-us" className="nav-link">Contact Us</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">Feedback &amp; Complaints</a></li>
                                        <li className="nav-item mb-2"><a href="#!" className="nav-link">How to Order</a></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-6 col-md-3">
                                    <h6 className="mb-4">Contact Information</h6>
                                    <ul className="nav flex-column">
                                        {/* list */}
                                        <li className="nav-item mb-2 d-flex align-items-flex-start">
                                            <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" className="bi bi-geo-alt-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                            </svg>
                                            &nbsp;<a href="https://www.google.com/maps?q=512A+Shakuntla+Building+No+59,+Nehru+Place,+New+Delhi+-+110019" target="_blank" className="nav-link">
                                                512A Shakuntla Building No 59,<br /> Nehru Place, New Delhi - 110019
                                            </a>
                                        </li>
                                        <li className="nav-item mb-2 d-flex align-items-flex-start">
                                            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-telephone-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z" />
                                            </svg>
                                            &nbsp;<a href="tel:+9101294161888" className="nav-link">(+91) 0129-4161888</a>
                                        </li>
                                        <li className="nav-item mb-2 d-flex align-items-flex-start">
                                            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-envelope-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                                            </svg>
                                            &nbsp;<a href="mailto:contact@v2umart.com" className="nav-link">contact@v2umart.com</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="border-top py-4">
                        <div className="row align-items-center">
                            <div className="col-lg-5 text-lg-start text-center mb-2 mb-lg-0">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item">Payment Partners</li>
                                    <li className="list-inline-item">
                                        <a href="#!"><img src="assets/images/payment/amazonpay.svg" alt='image' /></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#!"><img src="assets/images/payment/american-express.svg" alt='image' /></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#!"><img src="assets/images/payment/mastercard.svg" alt='image' /></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#!"><img src="assets/images/payment/paypal.svg" alt='image' /></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#!"><img src="assets/images/payment/visa.svg" alt='image' /></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-7 mt-4 mt-md-0">
                                <ul className="list-inline text-md-end mb-0 small mt-3 mt-md-0">
                                    <li className="list-inline-item text-muted">Follow us on</li>
                                    {socialLinks.map((link) => (
                                        <li key={link.id} className="list-inline-item me-1">
                                            <a href={link.url} target="_blank" rel="noopener noreferrer" className="btn btn-xs btn-social btn-icon">
                                                {getSocialIcon(link.platform)}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="border-top py-4">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <span className="small text-muted">
                                    Copyright ©2024 All Rights Reserved. | Webmaster
                                    <a href="#">Krishna Softweb</a>.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
