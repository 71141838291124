import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Hooks/useAuth';

export default function Header() {
    const [CreditLimit, setCreditLimit] = useState([]);
    const { isAuthenticated, customerName } = useAuth();
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            axios.get(`${BASE_URL}/credit-limit`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                },
            })
                .then(response => {
                    setCreditLimit(response.data.data.credit_limit);
                })
                .catch(error => {
                    console.error("Error fetching credit limit:", error);
                })
                .finally(() => setLoading(false));
        }
    }, [isAuthenticated]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchQuery.trim()) {
            navigate(`/shop?search=${encodeURIComponent(searchQuery)}`);
        }
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        navigate('/shop');
    };

    useEffect(() => {
        const input = document.getElementById('search-input');
        if (input) {
            input.addEventListener('search', handleClearSearch);
        }
        return () => {
            if (input) {
                input.removeEventListener('search', handleClearSearch);
            }
        };
    }, []);

    return (
        <>
            <div className="border-bottom">
                <div className="bg-light py-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-8 col-7 d-flex">
                            </div>
                            <div className="col-xl-9 col-lg-4 col-5 d-md-flex align-items-center justify-content-end">
                                <a href="/requestitem" className="btn btn-outline-primary btn-sm me-3">Request An Item</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-5">
                    <div className="container">
                        <div className="row w-100 align-items-center gx-lg-2 gx-0">
                            <div className="col-xxl-2 col-lg-3 col-md-6 col-5">
                                <a className="navbar-brand d-none d-lg-block" href="./index.html">
                                    <a href="/"><img src="../assets/images/b2b/logo.png" alt="v2u" /></a>
                                </a>
                                <div className="d-flex justify-content-between w-100 d-lg-none">
                                    <a className="navbar-brand" href="./index.html">
                                        <a href="/"><img src="../assets/images/b2b/logo.png" alt="v2u" /></a>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xxl-5 col-lg-5 d-none d-lg-block">
                                <form onSubmit={handleSearchSubmit}>
                                    <div className="input-group">
                                        <input
                                            id="search-input"
                                            className="form-control"
                                            type="search"
                                            placeholder="Search for products"
                                            aria-label="Search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <button className="btn btn-primary" type="submit">Search</button>
                                    </div>
                                </form>
                            </div>

                            <div className="col-lg-4 col-xxl-4 text-end col-md-6 col-7">
                                <div className="list-inline">
                                    <div className="list-inline-item me-5">
                                        <a href="#" className="text-muted position-relative">
                                            <div className="text-center">
                                                <i className="bi bi-bell fs-4" />
                                                <p className="mb-0 d-none text-reset d-xl-block">Notification</p>
                                            </div>
                                        </a>
                                    </div>

                                    {!isAuthenticated ? (
                                        <div className="list-inline-item me-5">
                                            <div className="text-center text-muted">
                                                <i className="bi bi-person-circle d-none d-xl-block fs-4" />
                                                <div className="d-flex">
                                                    <span><a href="/signup" className="text-reset d-none d-xl-block"> Sign Up /</a></span>
                                                    <span><a href="/signin" className="text-reset d-none d-xl-block"> Sign In </a></span>
                                                </div>
                                                <a href="/signin" className="text-reset d-xl-none">
                                                    <i className="bi bi-person-circle fs-4" />
                                                </a>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="list-inline-item me-5">
                                                <a className="text-muted position-relative" href="/setting">
                                                    <div className="text-center">
                                                        <i className="bi bi-person-circle fs-4" />
                                                        <p className="mb-0 text-reset d-none d-xl-block small">{customerName}</p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="list-inline-item me-5">
                                                <a className="text-muted position-relative" href="/walletHistory">
                                                    <div className="text-center">
                                                        <i className="bi bi-wallet2 fs-4" />
                                                        <p className="mb-0 text-reset d-none d-xl-block small">₹{CreditLimit ?? 0}</p>
                                                    </div>
                                                </a>
                                            </div>
                                        </>
                                    )}

                                    <div className="list-inline-item me-5">
                                        <a className="text-muted position-relative" href="/cart">
                                            <div className="text-center">
                                                <i className="bi bi-cart2 fs-4" />
                                                <p className="d-none text-reset d-xl-block">Cart</p>
                                            </div>
                                        </a>
                                    </div>

                                    <div className="list-inline-item d-inline-block d-lg-none">
                                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbar-default" aria-controls="navbar-default">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="currentColor" className="bi bi-text-indent-left text-primary" viewBox="0 0 16 16">
                                                <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-light navbar-default py-0 pb-lg-4" aria-label="Offcanvas navbar large">
                    <div className="container">
                        <div className="offcanvas offcanvas-start" tabIndex={-1} id="navbar-default" aria-labelledby="navbar-defaultLabel">
                            <div className="offcanvas-header pb-1">
                                <a href=""><img src="../assets/images/b2b/logo.png" alt="" /></a>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                            </div>
                            <div className="offcanvas-body">
                                <div className="d-block d-lg-none mb-4">
                                    <form onSubmit={handleSearchSubmit}>
                                        <div className="input-group">
                                            <input
                                                className="form-control"
                                                type="search"
                                                placeholder="Search for products"
                                                aria-label="Search"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                            <button className="btn btn-primary" type="submit">Search</button>
                                        </div>
                                    </form>

                                </div>

                                <div>
                                    <ul className="navbar-nav align-items-center">
                                        <li className="nav-item dropdown w-100 w-lg-auto">
                                            <a className="nav-link" href="/" role="button">Home</a>
                                        </li>
                                        <li className="nav-item dropdown w-100 w-lg-auto">
                                            <a className="nav-link" href="/about-us" role="button">About Us</a>
                                        </li>
                                        <li className="nav-item dropdown w-100 w-lg-auto">
                                            <a className="nav-link" href="/contact-us" role="button">Contact Us</a>
                                        </li>
                                        <li className="nav-item dropdown w-100 w-lg-auto">
                                            <a className="nav-link" href="/privacy-policy" role="button">Privacy Policy</a>
                                        </li>
                                        <li className="nav-item dropdown w-100 w-lg-auto dropdown-fullwidth">
                                            <a className="nav-link " href="/shop" role="button">
                                                <button className="btn btn-primary btn-lg" type="button" id="button-addon2"><i className="bi bi-bag-heart me-2"></i>Shop</button>
                                            </a>
                                        </li>

                                        <li className="nav-item dropdown w-100 w-lg-auto">
                                            <a className="nav-link" href="/cancellation-refund-policy" role="button">Cancellation/Refund Policy</a>
                                        </li>
                                       
                                        <li className="nav-item dropdown w-100 w-lg-auto dropdown-flyout">
                                            <a className="nav-link" href="/terms-conditions" role="button">Terms and Conditions</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

        </>
    );
}